import {
  CREATE_CELL,
  CellContentsPayload,
  CellId,
  StaticCellId,
  StoryElementId,
  createStoryElementPayload,
  uuid,
} from "@hex/common";
import { useCallback } from "react";

import { DispatchAOResult } from "../../../atomic-operations";
import { useStore } from "../../../redux/hooks.js";
import {
  CellLocation,
  DereferenceChildCellOptions,
  GLOBAL_CELL_LOCATION,
  resolveCellLocation,
} from "../../../redux/slices/cell-location/index.js";
import { useHexVersionAOContext } from "../../../util/hexVersionAOContext";
import { useProjectContext } from "../../../util/projectContext.js";
import { useSelectCell } from "../useSelectCell";

export interface InsertCellV2Options extends DereferenceChildCellOptions {
  /**
   * @default global
   */
  location?: CellLocation;
  /**
   * When passed, used as the value for cellId in the produced CREATE_CELL operation
   */
  cellIdOverride?: CellId;
  runAfterInsertion?: boolean;
  excludeFromHistory?: boolean;
  // use for tracking purposes
  isTemplate?: boolean;
}

export function useInsertCellV2Op(): (
  contents: CellContentsPayload,
  options?: InsertCellV2Options,
) => CREATE_CELL {
  const store = useStore();
  const { hexVersionId } = useProjectContext();

  return useCallback(
    (
      contents,
      {
        cellIdOverride: newCellId = uuid() as CellId,
        excludeFromHistory,
        isTemplate,
        location = GLOBAL_CELL_LOCATION,
        runAfterInsertion,
        ...derefOptions
      } = {},
    ) => {
      const {
        order: insertAt,
        parentBlockCellId,
        parentCellId,
        parentComponentImportCellId,
      } = resolveCellLocation({
        state: store.getState(),
        hexVersionId,
        location,
        ...derefOptions,
      });

      return CREATE_CELL.create({
        cellId: newCellId,
        staticCellId: uuid() as StaticCellId,
        insertAfter: undefined,
        insertBefore: undefined,
        insertAt,
        contents,
        storyElement: createStoryElementPayload({
          id: uuid() as StoryElementId,
        }),
        runAfterInsertion,
        componentImportCellId:
          contents.type === "COMPONENT_IMPORT" ? contents.id : null,
        parentBlockCellId: parentBlockCellId ?? undefined,
        parentComponentImportCellId: parentComponentImportCellId ?? undefined,
        parentCellId: parentCellId ?? undefined,
        blockCellId: contents.type === "BLOCK" ? contents.id : null,
        excludeFromHistory,
        isTemplate,
      });
    },
    [hexVersionId, store],
  );
}

export function useInsertCellV2(): (
  contents: CellContentsPayload,
  options?: InsertCellV2Options,
) => { cellId: CellId; dispatchResult: DispatchAOResult } {
  const insertCellOp = useInsertCellV2Op();

  const { dispatchAO } = useHexVersionAOContext();
  const { selectCells } = useSelectCell();

  return useCallback(
    (contents, options) => {
      const operation = insertCellOp(contents, options);
      const dispatchResult = dispatchAO(operation);

      // not sure we should really be doing this here,
      // but going to leave it for back compat
      if (options?.location == null || options.location.type === "global") {
        // TODO(VELO-5389): fix this scrolling behavior
        selectCells(operation.payload.cellId);
      }

      return { cellId: operation.payload.cellId, dispatchResult };
    },
    [dispatchAO, insertCellOp, selectCells],
  );
}
